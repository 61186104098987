import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"

import Masthead from "../components/strapi/structure/Masthead"
import OneCol from "../components/strapi/structure/OneCol"
import Twocol from "../components/strapi/structure/TwoCol"
import EEslider from "../components/strapi/energy-efficiency/EEslider"
import Outropiggy from "../components/strapi/structure/Outropiggy"

export default function EnergyEfficiency({ data }) {
  return (
    <Layout>
      <SEO title="Energy Efficiency" />

      {data.allStrapiEnergyEfficiency.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Masthead
            mhtitle={node.ee_mh.mh_title}
            mhsubtitle={node.ee_mh.mh_subtitle}
            mhbackground={node.ee_mh.mh_bg.publicURL}
          />
          <OneCol
            title={node.ee_intro.onecol_title}
            description={node.ee_intro.onecol_description}
          />
          <Twocol
            title={node.ee_products.txtimg_title}
            description={node.ee_products.txtimg_description}
            blueTitle={node.ee_products.txtimg_bluetitle}
            blueLink={node.ee_products.txtimg_bluelink}
            bgColor={node.ee_products.txtimg_bgcolor}
            order={node.ee_products.txtimg_order}
            rowimg={node.ee_products.txtimg_img.publicURL}
          />
          <EEslider />
          <Twocol
            title={node.ee_operating.txtimg_title}
            description={node.ee_operating.txtimg_description}
            bgColor={node.ee_operating.txtimg_bgcolor}
            order={node.ee_operating.txtimg_order}
            rowimg={node.ee_operating.txtimg_img.publicURL}
          />
          <Outropiggy outro={node.ee_outro.piggy_outro} />
        </React.Fragment>
      ))}
    </Layout>
  )
}

export const query = graphql`
  query EnergyEfficiency {
    allStrapiEnergyEfficiency {
      edges {
        node {
          id # id of the node
          ee_mh {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          ee_intro {
            id
            onecol_title
            onecol_description
          }
          ee_products {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_bluelink
            txtimg_bluetitle
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          ee_operating {
            id
            txtimg_title
            txtimg_description
            txtimg_bgcolor
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          ee_outro {
            id
            piggy_outro
          }
        }
      }
    }
  }
`
